import React from "react";
// react plugins that creates an input with a date picker

import { Col, Container, Modal, ModalBody, Row } from "reactstrap";

// core components

function ModalBox({ isOpen, setModal }) {
  return (
    <>
      <div className="section section-javascript" id="javascriptComponents">
        <Container>
          <Row id="modals">
            <Modal isOpen={isOpen} toggle={() => setModal(false)}>
              <div className="modal-header justify-content-center">
                <button
                  className="close"
                  type="button"
                  onClick={() => setModal(false)}
                >
                  <i className="now-ui-icons ui-1_simple-remove"></i>
                </button>
                <h4 className="title title-up">Covid 19</h4>
              </div>
              <ModalBody className=" section-contact-us text-center">
                <p>
                  Transporte de personal para empresas. <br></br>
                  Servicio puerta a puerta, para que te muevas con facilidad y
                  seguridad. Contamos con una amplia variedad de unidades que se
                  ajustan a su necesidad. <br></br> <br></br>
                  El bienestar de nuestros usuarios y equipo empresario es
                  siempre nuestra prioridad. Por ello, se informa que ante los
                  hechos de salud pública actuales, hemos extremado las medidas
                  de limpieza y desinfección de nuestras unidades y
                  colaboradores. Asimismo, se ha instruido a todo el personal
                  respecto de las recomendaciones impartidas por el ministerio
                  de salud de la nación.
                </p>
              </ModalBody>
              {/*<div className="modal-footer">
                 <Button color="default" type="button">
                  Nice Button
                </Button> */}
              {/* <Button
                  color="default"
                  type="button"
                  onClick={() => setModal(false)}
                >
                  Cerrar
                </Button> 
              </div>*/}
              <div className=" section-contact-us text-center">
                  <Row>
                    <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                      <h5>info@viajeserevan.com.ar</h5>
                      <h5>11 3093-2303</h5>
                    </Col>
                  </Row>
              </div>
            </Modal>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ModalBox;
