import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/erevan/header2.jpeg") + ")"
          }}
          ref={pageHeader}
        >
            {/* <div className="section section-contact-us text-center"> */}
            <Row style={{ width: '100%', position: 'absolute', bottom: 0, justifyContent: 'center'}} >
              <h2 style={{...contactStyle}}>
                info@viajeserevan.com.ar  |  11-3093-2303  |  11-3151-2336  |  14554-1407
              </h2>
            </Row>
            {/* </div> */}

        </div>
        <div className="content-center">
          <Container>
            <h1 className="title">Viajes Erevan</h1>
            <div className="text-center">
              <Button
                className="btn-icon btn-round"
                color="info"
                href="https://www.instagram.com/viajeserevan?ref=viajeserevan"
                //onClick={e => e.preventDefault()}
              >
                <i className="fab fa-instagram"></i>
              </Button>
              <Button
                className="btn-icon btn-round"
                color="info"
                href="https://www.facebook.com/ViajesErevan/"
                //onClick={e => e.preventDefault()}
              >
                <i className="fab fa-facebook-square"></i>
              </Button>
              {/*  <Button
                className="btn-icon btn-round"
                color="info"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <i className="fab fa-twitter"></i>
              </Button>
              <Button
                className="btn-icon btn-round"
                color="info"
                href="#pablo"
                onClick={e => e.preventDefault()}
              > 
                <i className="fab fa-google-plus"></i>
              </Button>*/}
            </div>

          </Container>
        </div>
      </div>
    </>
  );
}

const contactStyle = { marginBottom: "0px", "height": "30px", fontWeight: 800, fontSize: 16, color: 'white' };

export default LandingPageHeader;
