import React, { useState } from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  Alert,
} from "reactstrap";

// core components
import ExamplesNavbar from "./../../components/Navbars/ExamplesNavbar";
import LandingPageHeader from "./../../components/Headers/LandingPageHeader";
import DefaultFooter from "./../../components/Footers/DefaultFooter";
import ModalBox from "./Modal";

const Contacto = () => {
  return (
    <div className="section section-contact-us text-center">
      <Container>
        <h2 className="title">Contactenos</h2>
        <Row>
          <Col className="text-center ml-auto mr-auto" lg="6" md="8">
            <h5>info@viajeserevan.com.ar</h5>
            <h5>11 3093-2303</h5>
            <h5>11 3151-2336</h5>
            <h5>4554-1407</h5>
            <h5>11 4444-1080</h5>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const Example = (props) => {
  return (
    <Alert color="danger">
      <h4>
        Transporte de personal para empresas. Servicio puerta a puerta, para que
        te muevas con facilidad y seguridad.
      </h4>
    </Alert>
  );
};

const clientImages = [
  "encompass_tiba.png",
  "telefe.jpeg",
  "viacom.png",
  "mtv.jpg",
  "aeropuerto.jpg",
  "america_tv.jpg",
  "canal_13.jpg",
  "carburando.png",
  "clarin.png",
  "endemol.png",
  "espn.jpg",
  "fox.png",
  "ideas_del_sur.jpg",
  "polka.png",
  "tyc.jpg",
  "artear.jpg",
  "playboy.png",
  "uar.jpg",
  "cuatro_cabezas.gif",
  "billiken.jpeg",
  "tve.jpg",
  "fibertel_cablevision.png",
  "personal.jpg"
];

function LandingPage() {
  const [modal, setModal] = React.useState(true);
  //React.useEffect(() => setModal)

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const contactoRef = React.useRef();
  return (
    <>
      <ModalBox isOpen={modal} setModal={setModal} />
      <ExamplesNavbar />
      {/* <Example/> */}

      <div className="wrapper">
        <LandingPageHeader contactoRef={contactoRef} />
        <div className="section section-about-us">
          <Container>
            {/*  Quines somos */}
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">¿Quiénes Somos?</h2>
                <h5 className="description">
                  Somos una pequeña empresa formada hace 30 años como resultado
                  de pedidos de servicio por parte del medio televisivo, que
                  alternando con viajes de turismo fueron solventando algo no
                  pensado ni programado basado en la buena atención y el
                  respeto. Nuestra especialización es el rubro de las
                  comunicaciones. Esto no excluye el que se realicen con
                  idéntica responsabilidad excursiones y movimientos a
                  terminales de viajes, donde ofrecemos variedad de vehículos de
                  confort para viajes largos así como también choferes
                  especializados para esa categoría.
                </h5>
              </Col>
            </Row>

            <div className="separator separator-primary"></div>

            {/* BODY */}
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/erevan/interior.jpeg") +
                        ")",
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/erevan/mini_bus.jpeg") +
                        ")",
                    }}
                  ></div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <h3>
                    Vehículos habilitados para cada trabajo, ya sea traslado de
                    equipos, personal técnico o particulares.
                  </h3>
                </Col>
                <Col md="5">
                  <p className="description">
                    • Vehículos de carga <br></br>• Combis <br></br>• Minibuses
                    de 14,19, 21, 25 pasajeros <br></br>• Micros de 40 hasta 59
                    pasajeros <br></br>• Motorhomes especialmente equipados para
                    estar, vestuario y/o maquillaje <br></br>• Vehículos para
                    promociones <br></br>• Vehículos Antiguos, etc.<br></br>
                  </p>
                </Col>
              </Row>
              {/* UNIDADES */}
              <h2 className="title text-center">Unidades</h2>
              <div className="team text-center">
                <Row>
                  <Col md="4">
                    <div className="team-player">
                      <img
                        alt="..."
                        className="img-fluid img-raised"
                        src={require("assets/img/erevan/car.jpeg")}
                      ></img>
                      <h4 className="title">Vehículos</h4>
                      {/* <p className="category text-info">Model</p> */}
                      <p className="description">
                        Vehículo con capacidad Hasta 4 pasajeros más conductor,
                        y la posibilidad de rebatir los asientos traseros para
                        conseguir un amplio espacio de carga. Aire
                        acondicionado. Equipo de sonido. GPS.{" "}
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="team-player">
                      <img
                        alt="..."
                        className="img-fluid img-raised"
                        src={require("assets/img/erevan/trafic.jpeg")}
                      ></img>
                      <h4 className="title">Trafics</h4>
                      {/* <p className="category text-info">Model</p> */}
                      <p className="description">
                        Vehículo con capacidad hasta 16 pasajeros más conductor{" "}
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="team-player">
                      <img
                        alt="..."
                        className="img-fluid img-raised"
                        src={require("assets/img/erevan/mini_bus.jpeg")}
                      ></img>
                      <h4 className="title">Mini buses</h4>
                      {/* <p className="category text-info">Model</p> */}
                      <p className="description">
                        Vehículo con capacidad hasta pasajeros{" "}
                      </p>
                    </div>
                  </Col>
                </Row>

                {/* Row 2 */}
                <Row>
                  <Col md="4">
                    <div className="team-player">
                      <img
                        alt="..."
                        className="img-fluid img-raised"
                        src={require("assets/img/erevan/bus.jpeg")}
                      ></img>
                      <h4 className="title">Buses</h4>
                      {/* <p className="category text-info">Model</p> */}
                      <p className="description">
                        Vehículo con capacidad hasta pasajeros.{" "}
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="team-player">
                      <img
                        alt="..."
                        className="img-fluid img-raised"
                        src={require("assets/img/erevan/eq_filmacion2.png")}
                      ></img>
                      <h4 className="title">Equipo Filmación</h4>
                      {/* <p className="category text-info">Model</p> */}
                      <p className="description">
                        Capacidad para 36 personas. Instalación 220v y 12v. 2
                        equipos de aire acondicionado de 3500 frigorias. Sistema
                        de calefacción central. Manteles (opcional). Generador
                        (opcional){" "}
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="team-player">
                      <img
                        alt="..."
                        className="img-fluid img-raised"
                        src={require("assets/img/erevan/eq_filmacion.png")}
                      ></img>
                      <h4 className="title">Mini buses</h4>
                      {/* <p className="category text-info">Model</p> */}
                      <p className="description">
                        Capacidad para 36 personas. Instalación 220v y 12v. 2
                        equipos de aire acondicionado de 3500 frigorias. Sistema
                        de calefacción central. Manteles (opcional). Generador
                        (opcional){" "}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            {/* CLIENTES */}
            <div className="section-team text-center">
              <h2 className="title">Clientes</h2>
              <p className="description">
                Estas son las empresas que confian en nuestro servicio
              </p>
              <div className="team">
                <Row>
                  {clientImages.map((image) => (
                    <Col md="2" key={image}>
                      <div className="team-player">
                        <img
                          alt="..."
                          className="rounded-circle img-fluid img-raised"
                          src={require(`assets/img/erevan/clientes/${image}`)}
                        ></img>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </Container>
        </div>

        <Contacto />

        {/* <div className="section section-contact-us text-center">
          <Container>
            <h2 className="title">Contactenos</h2>
            <p className="description">Escribanos su mensaje</p>
            <Row>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                <InputGroup
                  className={
                    "input-lg" + (firstFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons users_circle-08"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Nombre"
                    type="text"
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    "input-lg" + (lastFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="text"
                    onFocus={() => setLastFocus(true)}
                    onBlur={() => setLastFocus(false)}
                  ></Input>
                </InputGroup>
                <div className="textarea-container">
                  <Input
                    cols="80"
                    name="name"
                    placeholder="Mensaje"
                    rows="4"
                    type="textarea"
                  ></Input>
                </div>
                <div className="send-button">
                  <Button
                    block
                    className="btn-round"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="lg"
                  >
                    Enviar
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div> */}
        <DefaultFooter />
      </div>
    </>
  );
}

export default LandingPage;
